import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./router/router";
import { Links } from "./components/common/links";
import { Background, MobileBackground } from "./static/bg_images";

const container = document.getElementById("root")!;
const root = createRoot(container);


root.render(
    <React.StrictMode>
        <div
            className="h-screen bg-cover bg-origin-content p-6 bg-no-repeat lg:bg-[url('./static/bg.svg')] xl:bg-[url('./static/bg.svg')] 2xl:bg-[url('./static/bg.svg')] md:bg-[url('./static/mobile_bg.svg')] sm:bg-[url('./static/mobile_bg.svg')]"
        >
            <div className="flex justify-center flex-col h-full">
                <div className="lg:text-3xl font-semibold text-center lg:pb-8 sm:text-center sm:text-2xl sm:pb-4">Industries #1 AI writing software will be</div>
                <div className="text-8xl font-semibold text-center text-main md:text-center sm:text-center 2xl:text-8xl xl:md:text-8xl lg:text-8xl md:text-4xl sm:text-4xl">LAUNCHING SOON!</div>
                <div className="text-md font-medium text-center pt-20">Join the waitlist for exclusive deals, offers, and early bird rewards!</div>
                <div className="text-md font-light text-center pt-4">
                    <input className="center lg:w-1/4 placeholder:text-slate-400 bg-white border border-slate-300 rounded-md py-4 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm sm:w-full" placeholder="Email" type="text" name="Email" />
                    <button className="rounded text-white lg:w-40 bg-main border border-slate-300 rounded-md ml-2 py-4 pl-2 pr-3 shadow-sm text-sm sm:w-full sm:ml-0 sm:mt-2">Subscribe</button>
                </div>
            </div>

            <div className="absolute bottom-4 right-0 left-0 text-sm font-thin text-center md:text-center sm:text-center">© Copyrights Nomadly | All Rights Reserved</div>

        </div>

        {/* <Provider store={store}>
            <BrowserRouter>
                <Links />
                <AppRouter />
            </BrowserRouter>
        </Provider> */}
    </React.StrictMode >
);
